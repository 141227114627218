.el-form-item[data-v-fb30d54a] {
  margin-bottom: 22px !important;
}
.top_select[data-v-fb30d54a] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item-p[data-v-fb30d54a] {
  border-top: 1px solid #efefef;
}
.item-p[data-v-fb30d54a]:nth-child(1) {
  border-top: none;
}